import Consts from 'consts'
import Dispatcher from 'shared/utils/dispatcher'
import moment from 'moment-timezone'
import {
  difference, find, forEach,
  isArray, isEmpty, isObject,
  map, reduce
} from 'lodash'
import rollbar from 'shared/utils/logging/integrations/rollbar'

Utils = {
    combineStyles: (...styles) ->
      newCombinedStyles = {}
      styles.forEach((styleObject) ->
        if isObject(styleObject) && !isArray(styleObject)
          forEach(styleObject, (value, key) ->
            newCombinedStyles[key] = value
          )
        if isArray(styleObject)
          stylesFromArray = Utils.combineStyles(...styleObject)
          forEach(stylesFromArray, (value, key) ->
            newCombinedStyles[key] = value
          )
      )
      return newCombinedStyles

    getPlural: (word) ->
      lastLetter  = word.slice(-1)

      pluralMap =
        y: =>  return word.slice(0, -1) + "ies"
        s: =>  return word += "es"


      if pluralMap[lastLetter]
        return pluralMap[lastLetter]()

      return word+ 's'

    splitIntoThree: (arr) ->
      first_list_size = Math.ceil(arr.length / 3) - 1
      second_list_size = first_list_size + Math.ceil((arr.length - 1) / 3)
      first_list = arr[0..first_list_size]
      second_list = arr[(first_list_size+1)..second_list_size]
      third_list = arr[(second_list_size+1)..]
      return [first_list, second_list, third_list]

    isSwoopJob: (UserStore, job) ->
      # type=='FleetManagedJob' is always indicative of a Swoop job
      # this alternative because job.account.name is not available in all areas of the app
      UserStore.isSwoop() || job?.account?.name == "Swoop" || job?.type == 'FleetManagedJob'

    isPartnerOnSwoopJob: (UserStore, job) ->
      UserStore.isPartner() && Utils.isSwoopJob(UserStore, job)

    isMetromileJob: (UserStore, job) ->
      UserStore.isMetromile() || job?.owner_company?.name == "Metromile"

    isFleetResponseJob: (UserStore, job) ->
      UserStore.isFleetResponse() || job?.owner_company?.name == "Fleet Response"

    isEnterpriseFleetManagementJob: (UserStore, job) ->
      UserStore.isEnterpriseFleetManagement() || job?.owner_company?.name == Consts.ENTERPRISE_FLEET_MANAGEMENT

    handleError: (data, textStatus, errorThrown, force) =>
      if Dispatcher? and Dispatcher.send?
        obj = {
          data: data
          textStatus: textStatus
          errorThrown: errorThrown
        }
        if force
          obj.forceMsg = "Uh oh! There was an issue saving your change. Please refresh and try again."
        #TODO: would be better to put in consts and grab from there
        Dispatcher.send("show_error", obj)

    downloadFile: (sUrl) ->
      link = document.createElement('a')
      link.href = sUrl
      if link.download != undefined
        #Set HTML5 download attribute. This will prevent file from opening if supported.
        fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length)
        link.download = fileName
      #Dispatching click event.
      if document.createEvent
        e = document.createEvent('MouseEvents')
        e.initEvent 'click', true, true
        link.dispatchEvent e
        return true
      query = '?download'
      window.open sUrl + query

    # checks if the given site_id is from a Site (and not from a Place for instance)
    isSiteAndNotPlace: (SiteStore, siteId) ->
      if !siteId?
        return false

      if SiteStore.exists(siteId)
        return true

      return false

    formatAddressWithSite: (siteName, siteLocation) ->
      siteName + " " + siteLocation?.address

    isInvoiceEditable: (UserStore, invoice) ->
      #If created by partner let them edit it
      if UserStore.isPartner() && !invoice.recipient?.company?
        return true

      if invoice.state? and invoice.state not in [
          Consts.IONSITEPAYMENT, Consts.ICREATED, Consts.IPARTNERNEW,
          Consts.IPARTNERAPPROVED,  Consts.IFLEETREJECTED,
          Consts.ISWOOPREJECTEDPARTNER, Consts.ISWOOPNEW
        ]
          return false

      else if UserStore.isSuperCompany()
        if invoice.state in [Consts.IPARTNERAPPROVED, Consts.IONSITEPAYMENT]
          return false

      return true

    turnIntoClassName: (name) ->
      if name?
        return name.replace(/\./g, "").replace(/\ /g, "")
      return ""

    formatDateTime: (timeStr, forceDate) ->
        #if same day
        if not timeStr?
            return ""

        if forceDate? || moment().format("MM/DD/YY") != moment(timeStr).format("MM/DD/YY")
            return moment(timeStr).format("MM/DD/YY HH:mm")
        else
            return moment(timeStr).format("HH:mm")

    compare: (str1, str2, dir) ->
        if not str1 || typeof str1 == "object"
          return dir
        if not str2 || typeof str2 == "object"
          return dir * -1

        regex = /^\d+$/
        if regex.test(str1) and regex.test(str2) and !isNaN(parseFloat(str1)) and !isNaN(parseFloat(str2))
          str1 = parseFloat(str1)
          str2 = parseFloat(str2)

        if typeof str1 == "string"
          return str1.toLowerCase().localeCompare(str2.toLowerCase())*dir
        else
          return (str1 - str2)*dir

    # Deprecated: use UserStore.getEndpoint()
    getEndpoint: (UserStore) ->
      if UserStore.isRoot() or UserStore.isSwoopDispatcher()
        return 'root'
      if UserStore.isPartner()
        return 'partner'
      if UserStore.isFleet()
        return 'fleet'

    hasKey: (obj, path) ->
      if obj? and path?
        keys = path.split('.')
        while keys.length > 0
          key = keys.shift()
          if obj.hasOwnProperty(key)
            obj = obj[key]
          else
            return false
        return true
      return false


    setValue: (obj, path, val) ->
      #TODO: could do this in a loop if will be nesting more than 1 level deep
      if obj? and path?
        name = path.split(".")
        tempObj = obj
        for i in [0..name.length-1]
          p = name[i]
          #TODO: mayb convet numbers to ints here
          if i == name.length-1
            tempObj[p] = val
          else
            if not tempObj[p]
              tempObj[p] = {}
            tempObj = tempObj[p]

    isAssignedFromFleet: (job) ->
        if !job?
          return false
        return job.owner_company? and job.rescue_company? and job.rescue_company.id != job.owner_company.id

    shortenAddress: (address) ->
        if typeof address == "string"
            address = address.replace(", United States", "")
                    .replace(", USA", "")

        #remove trailing zip code if it exists
        address

    # Calculates distance between two lat/lng points.
    # This algorithm follows the Haversine Formula and is based on:
    # http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
    calculateDistanceBetween: (originLatLng, comparisionLatLng) ->
      RADIUS = 6371 # Radius of the earth in km
      originLat = originLatLng.lat
      originLng = originLatLng.lng
      comparisionLat = comparisionLatLng.lat
      comparisionLng = comparisionLatLng.lng
      dLat = @deg2rad(comparisionLat - originLat)
      dLon = @deg2rad(comparisionLng - originLng)

      haversine_calculus_a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(@deg2rad(originLat)) * Math.cos(@deg2rad(comparisionLat)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)

      haversine_calculus = 2 * Math.atan2(Math.sqrt(haversine_calculus_a),
        Math.sqrt(1 - haversine_calculus_a))

      return RADIUS * haversine_calculus

    # helper function to Utils.calculateDistanceBetween
    deg2rad: (deg) ->
      return deg * (Math.PI/180)

    isValidEmail: (email) ->
        re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return (not email?) || email == "" || re.test(email.trim())

    isIOS: () ->
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    
    isMobile: () ->
      return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()))

    addMissingIds: (obj1, obj2) ->
      if typeof obj1 is "object" && typeof obj2 is "object" and obj1 != null and obj2 != null and obj1.constructor != Array
        if not obj1.id?
          for prop of obj1
            @addMissingIds(obj1[prop], obj2[prop])
          obj1.id = obj2.id
    compareObjs: (newObj, obj1, obj2) ->

      if obj1 and obj1.constructor == Array
        #if obj is an array (TODO: currently this assumes all primitive values in an array will fail for arrays of objects)

        for elem1 in obj1
          if typeof elem1 is "object"
            if not elem1.id?
              if not newObj?
                newObj = []
              newObj.push(elem1)
            else
              if obj2? and obj2.constructor == Array
                for elem2 in obj2
                  if not elem2? or elem1.id == elem2.id
                    ret = @compareObjs({}, elem1, elem2)
                    if not isEmpty(ret)
                      ret.id = elem2.id
                      if not newObj?
                        newObj = []
                      newObj.push(ret)
                    break
          else
            if not obj2 || difference(obj1, obj2).length > 0 || difference(obj2, obj1).length > 0
              newObj = obj1
      else
        #if obj1 is an object
        for prop of obj1
          if typeof obj1[prop] is "object" && obj1[prop] != null
            if typeof newObj[prop] is "undefined"
              if obj1[prop].constructor == Array
                newObj[prop] = @compareObjs(null, obj1[prop], obj2?[prop])
                if newObj[prop] == null
                  delete newObj[prop]
              else
                newObj[prop] = @compareObjs({}, obj1[prop], obj2?[prop])
                if isEmpty(newObj[prop]) and newObj[prop].constructor == Object
                  delete newObj[prop]

          else
            if (typeof obj2 == "undefined" or obj2 == null) or (typeof obj2[prop] == "undefined" or obj2[prop] == null and obj1[prop] != null) || obj1[prop] != obj2[prop]
              newObj[prop] = obj1[prop]
              if newObj[prop] == ""
                newObj[prop] = null
      return newObj

    carouselImgURL: (photoURL, w, h) ->
      if Consts.JS_ENV == 'local'
        return photoURL
      return '//images.weserv.nl/?url=' + photoURL + '&w=' + w + '&h=' + h + '&t=square 2x'
}
export default Utils
